.react-grid-item {
  /* transition: all 200ms ease;
  transition-property: left, top; */
  /* background: #ccc; */
  border: 1px solid black;
}
.react-grid-item.cssTransforms{
  transition:none;
}

@font-face {
  font-family: "Jazz Ball Bold";
  src: url("../../assets/fonts/Jazz_Ball/Jazz_Ball_Bold.ttf");
}
@font-face {
  font-family: "Jazz Ball Regular";
  src: url("../../assets/fonts/Jazz_Ball/Jazz_Ball_Regular.ttf");
}

@font-face {
  font-family: "Victoria";
  src: url("../../assets/fonts/Victoria.otf");
}

@font-face {
  font-family: "Twig Regular";
  src: url("../../assets/fonts/Twig-Regular.otf");
}

@font-face {
  font-family: "Sketch Block";
  src: url("../../assets/fonts/Sketch_Block.ttf");
}

@font-face {
  font-family: "Peace Sans";
  src: url("../../assets/fonts/Peace\ Sans.otf");
}

@font-face {
  font-family: "Neilvard Three";
  src: url("../../assets/fonts/Neilvard\ Three.otf");
}

@font-face {
  font-family: "Zion";
  src: url("../../assets/fonts/Zion/Zion.ttf");
}

@font-face {
  font-family: "Titilium Web";
  src: url("../../assets/fonts/titillium-web/TitilliumWeb-Regular.ttf");
}

@font-face {
  font-family: "Spectral";
  src: url("../../assets/fonts/spectral/Spectral-Regular.ttf");
}

@font-face {
  font-family: "Rubik";
  src: url("../../assets/fonts/rubik/Rubik-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("../../assets/fonts/roboto/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Raleway";
  src: url("../../assets/fonts/raleway/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Morton";
  src: url("../../assets/fonts/Morton_EnvatoElements/OTF/Morton-Regular.otf");
}

@font-face {
  font-family: "Lora";
  src: url("../../assets/fonts/lora/Lora-Regular.ttf");
}

@font-face {
  font-family: "Facile Sans";
  src: url("../../assets/fonts/facile_sans/Facile\ Sans.ttf");
}

@font-face {
  font-family: "FF Mark Pro Book";
  src: url("../../assets/fonts/FF\ Mark\ Pro\ Book.ttf");
}

@font-face {
  font-family: "FF Mark Pro Bold";
  src: url("../../assets/fonts/FF\ Mark\ Pro\ Bold.ttf");
}

@font-face {
  font-family: "Montserrat Light";
  src: url("../../assets/fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("../../assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Industry Bold";
  src: url("../../assets/fonts/Industry/Industry-Bold.ttf");
}

@font-face {
  font-family: "Industry Demi";
  src: url("../../assets/fonts/Industry/Industry-Demi.ttf");
}

@font-face {
  font-family: "Aktiv Grotesk Corp Regular";
  src: url("../../assets/fonts/Aktiv\ Grotesk\ Corp/AktivGroteskCorp-Regular.ttf");
}

@font-face {
  font-family: "Aktiv Grotesk Corp Bold";
  src: url("../../assets/fonts/Aktiv\ Grotesk\ Corp/AktivGroteskCorp-Bold.ttf");
}

@font-face {
  font-family: "Exo Bold";
  src: url("../../assets/fonts/Exo-Bold.ttf");
}

@font-face {
  font-family: "Exo SemiBold";
  src: url("../../assets/fonts/Exo-SemiBold.ttf");
}

@font-face {
  font-family: "Gotham Black";
  src: url("../../assets/fonts/Gotham-Black.ttf");
}

@font-face {
  font-family: "Mission Gotham Black";
  src: url("../../assets/fonts/Mission-Gothic-Black.ttf");
}
@font-face {
  font-family: "Mission Gotham Bold";
  src: url("../../assets/fonts/Mission-Gothic-Bold.ttf");
}

@font-face {
  font-family: "Mission Gotham Regular";
  src: url("../../assets/fonts/Mission-Gothic-Regular.ttf");
}

@font-face {
  font-family: "Helvetica Bold";
  src: url("../../assets/fonts/Helvetica-Bold.ttf");
}

@font-face {
  font-family: "Knockout Heavy";
  src: url("../../assets/fonts/Knockout/KnockoutHeavy.ttf");
}

@font-face {
  font-family: "Knockout Junior";
  src: url("../../assets/fonts/Knockout/KnockoutJunior.ttf");
}

@font-face {
  font-family: "Worker Black";
  src: url("../../assets/fonts/Worker/Worker-Black.ttf");
}

@font-face {
  font-family: "Bicyclette Black";
  src: url("../../assets/fonts/Bicyclette/Bicyclette-Black.ttf");
}

@font-face {
  font-family: "Bicyclette Bold";
  src: url("../../assets/fonts/Bicyclette/Bicyclette-Bold.ttf");
}

@font-face {
  font-family: "Prompt Extra Light";
  src: url("../../assets/fonts/Prompt-ExtraLight.ttf");
}

@font-face {
  font-family: "Julius Sans One Regular";
  src: url("../../assets/fonts/JuliusSansOne-Regular.ttf");
}
