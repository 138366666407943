@font-face {
  font-family: "Roboto Black";
  src: url("../../assets/fonts/roboto/Roboto-Black.ttf");
}
@font-face {
  font-family: "Roboto Bold";
  src: url("../../assets/fonts/roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "Roboto Regular";
  src: url("../../assets/fonts/roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto Medium";
  src: url("../../assets/fonts/roboto/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto Light Italic";
  src: url("../../assets/fonts/roboto/Roboto-LightItalic.ttf");
}
